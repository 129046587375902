<template>
	<svg width="82" height="76" viewBox="0 0 82 76" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_108_989)">
			<path
				d="M19.9404 49.2076C19.9404 49.2076 24.8398 45.4717 31.1482 45.4717C37.4566 45.4717 46.0919 51.3915 53.5637 52.9435C61.0355 54.4955 69.6709 52.7492 75.9792 54.107C82.2876 55.4647 81.5105 60.2083 75.9792 62.785C70.4479 65.3617 59.3853 66.9159 53.5637 66.9159C47.7421 66.9159 19.9404 66.9159 19.9404 66.9159V49.2076Z"
				fill="white"
				stroke="black"
				stroke-width="1.28089"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M13.1838 75.3588H8.01547C4.28381 75.3588 1.25879 72.3338 1.25879 68.6021V48.4901C1.25879 44.7584 4.28381 41.7334 8.01547 41.7334H13.1838C16.9155 41.7334 19.9405 44.7584 19.9405 48.4901V68.6021C19.9405 72.3338 16.9155 75.3588 13.1838 75.3588Z"
				fill="white"
				stroke="black"
				stroke-width="1.28089"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M52.8188 0.665982C48.4851 0.373513 44.6446 2.54461 42.5396 5.91335C41.7583 7.16435 39.9224 7.16435 39.1432 5.91335C37.0382 2.54248 33.1977 0.373513 28.864 0.665982C22.4682 1.09721 18.0747 6.98289 18.4611 13.3809C19.0738 23.5149 25.7985 32.3359 35.5332 35.5552L37.2731 36.1252C39.6086 36.8895 42.1276 36.8852 44.461 36.1145L46.1475 35.5573C55.8822 32.338 62.6069 23.517 63.2196 13.3831C63.6081 6.98502 59.2147 1.09935 52.8188 0.665982Z"
				fill="white"
				stroke="black"
				stroke-width="1.28089"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_108_989">
				<rect width="80.4461" height="76" fill="white" transform="translate(0.618164)" />
			</clipPath>
		</defs>
	</svg>
</template>
