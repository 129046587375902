<template>
	<svg width="79" height="76" viewBox="0 0 79 76" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_108_974)">
			<path
				d="M49.1772 75.3597H29.8208C20.749 75.3597 13.3926 67.9924 13.3926 58.9023V17.098C13.3926 8.01006 20.7468 0.640625 29.8208 0.640625H49.1772C58.2491 0.640625 65.6054 8.00793 65.6054 17.098V58.9044C65.6054 67.9924 58.2512 75.3597 49.1772 75.3597Z"
				fill="white"
				stroke="black"
				stroke-width="1.2809"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M70.8269 24.5664H57.4013C53.6933 24.5664 50.6885 27.5765 50.6885 31.2911V44.7406C50.6885 48.4552 53.6933 51.4653 57.4013 51.4653H57.7572V53.5894C57.7572 55.9911 60.6575 57.1952 62.3517 55.4958L66.3474 51.493C66.356 51.4845 66.3581 51.4738 66.3666 51.4653H70.8269C74.5349 51.4653 77.5397 48.4552 77.5397 44.7406V31.2911C77.5397 27.5765 74.5349 24.5664 70.8269 24.5664Z"
				fill="white"
				stroke="black"
				stroke-width="1.2809"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M8.17375 24.5664H21.5994C25.3074 24.5664 28.3122 27.5765 28.3122 31.2911V44.7406C28.3122 48.4552 25.3074 51.4653 21.5994 51.4653H21.2435V53.5894C21.2435 55.9911 18.3431 57.1952 16.6489 55.4958L12.6532 51.493C12.6447 51.4845 12.6426 51.4738 12.634 51.4653H8.17375C4.46572 51.4653 1.46094 48.4552 1.46094 44.7406V31.2911C1.46094 27.5765 4.46572 24.5664 8.17375 24.5664Z"
				fill="white"
				stroke="black"
				stroke-width="1.2809"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M19.9792 32.3351C17.993 31.5922 16.1049 32.5038 15.2973 34.0686C15.1225 34.4059 14.6494 34.4059 14.4747 34.0686C13.667 32.5038 11.7789 31.5943 9.79276 32.3351C8.37987 32.8646 7.5104 34.2736 7.51466 35.785C7.52106 39.3224 9.7885 42.4585 13.1428 43.5686L13.7778 43.7757C14.5045 44.0148 15.2887 44.0127 16.0133 43.7714L16.6313 43.5665C19.9856 42.4564 22.2509 39.3182 22.2594 35.7829C22.2594 34.2736 21.3921 32.8646 19.9792 32.3351Z"
				stroke="black"
				stroke-width="1.2809"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M59.4824 41.558V33.2236C59.4824 31.8786 60.9507 31.0525 62.0972 31.7505L68.7141 35.779C69.7967 36.4386 69.8202 38.0056 68.7568 38.6973L62.1398 43.0032C60.9955 43.7483 59.4824 42.9243 59.4824 41.558Z"
				stroke="black"
				stroke-width="1.2809"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M35.7705 67.8877H43.2292"
				stroke="black"
				stroke-width="1.2809"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M44.1535 11.8485H34.843C32.2516 11.8485 30.1504 9.74355 30.1504 7.14759V0.640625H48.8461V7.14759C48.8461 9.74355 46.7449 11.8485 44.1535 11.8485Z"
				stroke="black"
				stroke-width="1.2809"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_108_974">
				<rect width="77.3571" height="76" fill="white" transform="translate(0.821289)" />
			</clipPath>
		</defs>
	</svg>
</template>
