<template>
	<div
		class="bg-center bg-cover mx-6 lg:mx-0 h-[310px] lg:h-[500px] relative rounded-tr-[60px] rounded-bl-[60px] flex flex-col items-center justify-center mt-8 lg:mt-0"
		:style="!player.playing && !player.currentTime ? { backgroundImage: `url(${thumbnailSrc})` } : {}"
	>
		<div v-if="!player.playing" class="flex flex-col items-center z-10">
			<button
				class="bg-[#0F0E0E] border-[9px] border-[#373737]/80 rounded-full w-fit p-5 hover:bg-[#CEF26F] group"
				@click="startVideo"
				title="Clique para ouvir"
			>
				<VolumeOff class="text-white group-hover:text-black" />
			</button>

			<Button class="mt-6 px-4 w-fit" variant="black" @click="startVideo" title="Clique para ouvir">
				Clique para ouvir
			</Button>
		</div>

		<div
			class="absolute top-0 left-0 w-full"
			:class="{
				'-z-10': !player.playing && !player.currentTime,
			}"
		>
			<video
				class="w-full rounded-tr-[60px] rounded-bl-[60px] h-[310px] object-cover lg:h-[500px]"
				:controls="false"
				ref="video"
				@click="player.playing = !player.playing"
			>
				<source :src="videoSrc" type="video/mp4" />
			</video>
		</div>
	</div>
</template>

<script setup lang="ts">
import { VolumeOff } from 'lucide-vue-next'

const videoRef = useTemplateRef('video')
const player = reactive({
	playing: false,
	currentTime: 0,
})
const props = defineProps<{
	videoSrc: string
	thumbnailSrc: string
}>()

function startVideo() {
	player.playing = true

	nextTick().then(() => {
		videoRef.value?.play()
	})
}

function updateCurrentTime(event: Event) {
	const target = event.target as HTMLVideoElement

	player.currentTime = target.currentTime
}

onUnmounted(() => {
	videoRef.value?.pause()

	videoRef.value?.removeEventListener('timeupdate', updateCurrentTime)
})

onMounted(() => {
	videoRef.value?.addEventListener('timeupdate', updateCurrentTime)
})

watch(
	() => player.playing,
	playing => {
		if (!playing) {
			videoRef.value?.pause()
		}
	}
)
</script>
