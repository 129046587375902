export const useDeviceStore = defineStore('device', () => {
	const device = reactive({
		isMobile: false,
		isTablet: false,
		isDesktop: false,
	})

	const setDevice = () => {
		device.isMobile = window.innerWidth < 768
		device.isTablet = window.innerWidth >= 768 && window.innerWidth < 1024
		device.isDesktop = window.innerWidth >= 1024
	}

	onMounted(() => {
		setDevice()
		window.addEventListener('resize', setDevice)
	})

	onBeforeUnmount(() => {
		window.removeEventListener('resize', setDevice)
	})

	return {
		device,
	}
})
