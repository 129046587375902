<template>
	<div>
		<label class="text-white text-base font-jakarta" :for="id" :class="labelClass" v-if="!!label">{{
			label
		}}</label>
		<div
			class="bg-[#141718] rounded-xl px-5 group focus-within:ring-stone-500/40 focus-within:ring-2 flex items-center gap-3"
			:class="{
				'mt-2': !!label,
			}"
		>
			<slot name="icon" />

			<Input
				v-bind="$attrs"
				class="bg-transparent w-full text-white outline-none h-fit py-5"
				wrapperClass="focus-within:ring-0"
				v-model="inputModel"
				:id="id"
				type="text"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
const id = useId()
const inputModel = defineModel()
defineProps({
	label: {
		type: String,
	},
	labelClass: {
		type: String,
	},
	inputClass: {
		type: String,
	},
})
</script>
