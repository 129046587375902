<template>
	<svg width="70" height="74" viewBox="0 0 70 74" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M4.48708 35.658L12.8016 25.3483L36.0199 54.338L33.0968 58.3022L4.48708 35.658Z"
			fill="#CEF26F"
		/>
		<path
			d="M3.40042 34.7514L11.6895 24.448C12.1911 23.8483 13.0745 23.7455 13.6856 24.2425C13.7811 24.3233 13.8665 24.4132 13.9383 24.5042L37.1255 53.4651C37.5416 53.9661 37.5385 54.6798 37.1572 55.1997L34.2273 59.148C33.7585 59.7805 32.8712 59.9177 32.2494 59.4395L3.61254 36.7648C2.99239 36.2906 2.89878 35.384 3.37949 34.7792L3.40212 34.7554L3.40042 34.7514ZM12.795 27.6057L6.49204 35.4301L32.8331 56.2783L34.2419 54.3751L12.799 27.604L12.795 27.6057Z"
			fill="black"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M30.4873 9.06104L48.3783 4.47306L61.3754 57.9303L55.2643 60.5541L30.4873 9.06104Z"
			fill="#CEF26F"
		/>
		<path
			d="M30.205 7.68216L48.0208 3.09363C48.7916 2.90331 49.5592 3.36116 49.7545 4.11161L49.7477 4.12856L49.7596 4.12348L62.7409 57.5875C62.913 58.273 62.5583 58.9642 61.9334 59.2325L55.8262 61.8546C55.1063 62.1637 54.2681 61.8252 53.9609 61.1088L53.9389 61.0573L29.2247 9.67789C28.878 8.9784 29.1749 8.11973 29.8807 7.7886C29.9784 7.73257 30.0903 7.6986 30.205 7.68216ZM47.3438 6.2012L32.5145 9.9996L55.9459 58.7145L59.7036 57.1012L47.3438 6.2012Z"
			fill="black"
		/>
	</svg>
</template>
