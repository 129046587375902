<template>
	<svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_108_948)">
			<path
				d="M43.879 29.1592C41.766 29.0174 39.8947 30.0739 38.8681 31.7176C38.4867 32.3267 37.5919 32.3267 37.2125 31.7176C36.1859 30.0739 34.3146 29.0174 32.2016 29.1592C29.084 29.3689 26.9411 32.2388 27.1308 35.3584C27.4304 40.2994 30.7077 44.5993 35.453 46.169L36.3018 46.4466C37.4402 46.8181 38.6684 46.8161 39.8048 46.4406L40.6276 46.169C45.3729 44.5993 48.6522 40.2994 48.9498 35.3584C49.1395 32.2388 46.9966 29.3689 43.879 29.1592Z"
				fill="white"
				stroke="black"
				stroke-width="1.1983"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M59.6596 37.9336H54.9463"
				stroke="black"
				stroke-width="1.1983"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M53.2688 22.6406L49.9355 25.9739"
				stroke="black"
				stroke-width="1.1983"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M37.9346 16.3438V21.0571"
				stroke="black"
				stroke-width="1.1983"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M22.6377 22.7344L25.971 26.0676"
				stroke="black"
				stroke-width="1.1983"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M16.3408 38.0684H21.0541"
				stroke="black"
				stroke-width="1.1983"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M22.7314 53.3635L26.0647 50.0303"
				stroke="black"
				stroke-width="1.1983"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M38.0664 59.6606V54.9473"
				stroke="black"
				stroke-width="1.1983"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M53.3616 53.2708L50.0283 49.9375"
				stroke="black"
				stroke-width="1.1983"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M70.6765 42.6596C73.2862 42.6596 75.4017 40.544 75.4017 37.9343C75.4017 35.3246 73.2862 33.209 70.6765 33.209C68.0668 33.209 65.9512 35.3246 65.9512 37.9343C65.9512 40.544 68.0668 42.6596 70.6765 42.6596Z"
				fill="white"
				stroke="black"
				stroke-width="1.1983"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M61.0593 19.5697C63.669 19.5697 65.7846 17.4541 65.7846 14.8444C65.7846 12.2347 63.669 10.1191 61.0593 10.1191C58.4496 10.1191 56.334 12.2347 56.334 14.8444C56.334 17.4541 58.4496 19.5697 61.0593 19.5697Z"
				fill="white"
				stroke="black"
				stroke-width="1.1983"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M37.9352 10.0482C40.5449 10.0482 42.6605 7.93265 42.6605 5.32294C42.6605 2.71324 40.5449 0.597656 37.9352 0.597656C35.3255 0.597656 33.21 2.71324 33.21 5.32294C33.21 7.93265 35.3255 10.0482 37.9352 10.0482Z"
				fill="white"
				stroke="black"
				stroke-width="1.1983"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M14.8474 19.6664C17.4571 19.6664 19.5726 17.5508 19.5726 14.9411C19.5726 12.3314 17.4571 10.2158 14.8474 10.2158C12.2377 10.2158 10.1221 12.3314 10.1221 14.9411C10.1221 17.5508 12.2377 19.6664 14.8474 19.6664Z"
				fill="white"
				stroke="black"
				stroke-width="1.1983"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M5.3249 42.7885C7.9346 42.7885 10.0502 40.6729 10.0502 38.0632C10.0502 35.4535 7.9346 33.3379 5.3249 33.3379C2.71519 33.3379 0.599609 35.4535 0.599609 38.0632C0.599609 40.6729 2.71519 42.7885 5.3249 42.7885Z"
				fill="white"
				stroke="black"
				stroke-width="1.1983"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M14.9411 65.8744C17.5508 65.8744 19.6664 63.7588 19.6664 61.1491C19.6664 58.5394 17.5508 56.4238 14.9411 56.4238C12.3314 56.4238 10.2158 58.5394 10.2158 61.1491C10.2158 63.7588 12.3314 65.8744 14.9411 65.8744Z"
				fill="white"
				stroke="black"
				stroke-width="1.1983"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M38.0671 75.3978C40.6768 75.3978 42.7924 73.2823 42.7924 70.6726C42.7924 68.0629 40.6768 65.9473 38.0671 65.9473C35.4574 65.9473 33.3418 68.0629 33.3418 70.6726C33.3418 73.2823 35.4574 75.3978 38.0671 75.3978Z"
				fill="white"
				stroke="black"
				stroke-width="1.1983"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M61.153 65.7836C63.7627 65.7836 65.8783 63.668 65.8783 61.0583C65.8783 58.4486 63.7627 56.333 61.153 56.333C58.5433 56.333 56.4277 58.4486 56.4277 61.0583C56.4277 63.668 58.5433 65.7836 61.153 65.7836Z"
				fill="white"
				stroke="black"
				stroke-width="1.1983"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_108_948">
				<rect width="76" height="76" fill="white" />
			</clipPath>
		</defs>
	</svg>
</template>
